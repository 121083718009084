/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-30 10:44
 * @description：分销管理-教练管理API
 * @update: 2023-05-30 10:44
 */
import {IPageRes} from "@/apis/page";
import {get, postJ, postW} from "@/request";
import {
  ITalent,
  ITalentByPageQuery,
  ITalentCustom,
  ITalentCustomQuery,
  IOrderCustomQuery,
  IOrderCustom
} from "@/apis/distributionManage/talentManage/types";

/**
 * 达人管理分页查询列表
 */
export const queryTalentByPageApi = (param:ITalentByPageQuery)=> get<IPageRes<ITalent[]>>("/golf/talentManage/queryTalentByPage", param)

/**
 * 达人管理删除
 */
export const talentRemoveApi = (id:string)=> get(`/golf/talentManage/remove/${id}`)

/**
 * 查询达人客户订单列表
 */
export const queryTalentCustomListApi = (param:IOrderCustomQuery)=> get<IPageRes<IOrderCustom[]>>(`/golf/talentManage/queryCustom/orderByPage`,param)

/**
 * 查询达人客户列表
 */
export const queryCustomListApi = (param:ITalentCustomQuery)=> get<IPageRes<ITalentCustom[]>>(`/golf/talentManage/queryCustomList`,param)

/**
 * 查询达人卡 二维码
 */
export const getTalentCode = (shareCode:string)=> get(`/golf/talentManage/getCode`,{shareCode},"blob")


/**
 * 批量新增达人管理
 */
export const talentBatchAddApi = (ids:string[])=> postW(`/golf/talentManage/batchAdd?ids=${ids.join(",")}`, {})

/**
 * 启用禁用
 */
export const talentStatusUpdateApi = (data: ITalent) => postJ("/golf/talentManage/statusUpdate", data)
